import { createModel } from '@rematch/core';
import xhr from '../../helpers/xhr';

export const permissions = createModel({
  state: '',
  reducers: {
    setPermissions(state, payload) {
      return payload;
    },
  },
  effects: dispatch => ({
    async refreshPermissions() {
      const { data } = await xhr().get(`/permissions/`);

      dispatch.permissions.setPermissions(JSON.stringify(data));
    },
    async hasPermission(perm, state) {
      return false;
    },
  }),
});
