import { Avatar, Button, Col, Divider, Input, Layout, Modal, PageHeader, Row, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { deleteEntity, fetchEntities } from '../../helpers/crud';
import { withRouter } from 'react-router';
import Column from 'antd/lib/table/Column';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../Constants';
import xhr from '../../helpers/xhr'

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;

const mapState = state => ({
  permissions: state.permissions,
});

const mapDispatch = dispatch => ({});

function EmployeeList(props) {
  const [results, setResults] = useState();
  const [originalResults, setOriginalResults] = useState();
  const [loading, setLoading] = useState();
  const [params, setParams] = useState();
  const [accessGranted, setAccessGranted] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 50,
  });

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetch = async (params = {}) => {
      setLoading(true);

      const { data } = await fetchEntities('products/grouped_by_product', params, source.token);

      if (data) {
        setLoading(false);
        setResults(data);
        setOriginalResults(data);
        // setPagination(p => {
        //   return { ...p, total: data.count };
        // });

        return data;
      }
    };

    fetch(params);

    return () => {
      source.cancel('Operation canceled by the user.');
    };
  }, [props.permissions, accessGranted]);

  const onSearch = value => {
    if (!value) {
      return setResults(originalResults);
    }

    setResults(results.filter(entry => {
      if (entry.product_name && entry.product_name.toLowerCase().includes(value.toLowerCase()))
      {
        return true;
      }

      else if (entry.product_code && entry.product_code.toLowerCase().includes(value.toLowerCase()))
      {
        return true;
      }

      else if (entry.product_description && entry.product_description.toLowerCase().includes(value.toLowerCase()))
      {
        return true;
      }

      else if (entry.batch_number && entry.batch_number.toLowerCase().includes(value.toLowerCase()))
      {
        return true;
      }
      
      return false;
    }));
  };

  const doExport = () => {
    return xhr().post(`${API_URL}/generate-token/`, null, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('claresse_token')}`
      }
      }).then((response) => {
        window.open(`${API_URL}/voorraad/?TOKEN=${response.data.key}`, '_blank');
      })
  }

  return (
    <Layout>
      <PageHeader title="Actuele voorraad" />
      <Content
        style={{
          background: '#fff',
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <>
          <Row>
            <Col
              lg={{
                span: 6,
              }}
              xs={24}
              style={{ marginBottom: 20 }}
            >
              <Search allowClear placeholder="Zoek op artikel, omschrijving of batch..." onSearch={onSearch} style={{ width: '100%' }} />
            </Col>
            <Col
              lg={{
                span: 6,
              }}
              xs={24}
              style={{ marginLeft: 20, marginBottom: 20 }}>
                <Button type="primary" onClick={doExport}>
                  Exporteren
                </Button>
            </Col>
          </Row>

          <Table
            scroll={{ x: true }}
            size="middle"
            rowClassName={(record, index) => (index % 2 ? 'even' : 'odd')}
            rowKey={record => record.id}
            dataSource={results}
            pagination={false}
            // pagination={pagination}
            loading={loading}
          >
            <Column
              title="Artikel"
              sorter={(a, b) => a.product_name && a.product_name.localeCompare(b.product_name)}
              key="product_name"
              dataIndex="product_name"
            />

          <Column
              title="Artikelcode"
              dataIndex="product_code"
              sorter={(a, b) => a.product_code && a.product_code.localeCompare(b.product_code)}
            />

            <Column
              title="Artikelomschrijving"
              sorter={(a, b) => a.product_description && a.product_description.localeCompare(b.product_description)}
              dataIndex="product_description"
            />
            <Column title="Aantal pallets" sorter={(a, b) => a.pallet_qty - b.pallet_qty} dataIndex="pallet_qty" />

            <Column
              title="Kilogram beschikbaar"
              sorter={(a, b) => a.colli_qty_available - b.colli_qty_available}
              dataIndex="colli_qty_available"
              render={value => {
                if (value) {
                  return value / 100;
                }

                return value;
              }}
            />

            <Column
              title="Verpakking"
              dataIndex="qty_uom_name"
              sorter={(a, b) => a.qty_uom_name && a.qty_uom_name.localeCompare(b.qty_uom_name)}
            />

            <Column
              title="THT"
              dataIndex="best_before_date"
              render={value => {
                if (value) {
                  return moment(value).format('DD-MM-YYYY');
                }

                return value;
              }}
              sorter={(a, b) => moment(a.best_before_date).unix() - moment(b.best_before_date).unix()}
            />

            <Column
              title="Batch/Lot"
              dataIndex="batch_number"
              sorter={(a, b) => a.batch_number && a.batch_number.localeCompare(b.batch_number)}
            />

            <Column
              title="Productiedatum"
              dataIndex="production_date"
              render={value => {
                if (value) {
                  return moment(value).format('DD-MM-YYYY');
                }

                return value;
              }}
              sorter={(a, b) => moment(a.production_date).unix() - moment(b.production_date).unix()}
            />

            <Column
              title="Status"
              dataIndex="status"
              sorter={(a, b) => a.status && a.status.localeCompare(b.status)}
            />

            <Column
              title="Inslagdatum"
              dataIndex="inbound_instruction_date"
              render={value => {
                if (value) {
                  return moment(value).format('DD-MM-YYYY');
                }

                return value;
              }}
              sorter={(a, b) => moment(a.inbound_instruction_date).unix() - moment(b.inbound_instruction_date).unix()}
            />
          </Table>
        </>
      </Content>
    </Layout>
  );
}

export default withRouter(connect(mapState, mapDispatch)(EmployeeList));
