import { ExceptionOutlined, SmileOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import React from 'react';
import axios from 'axios';
import xhr from '../helpers/xhr';

export const fetchEntities = async (uri, params = {}, cancelToken = null) => {
  if (params.hasOwnProperty('page')) {
    params = { ...params, start: (params.page - 1) * 50 };
  }

  if (!params.hasOwnProperty('limit')) {
    params = { ...params, limit: 50 };
  }

  if (params.hasOwnProperty('sortField') && params.hasOwnProperty('sortOrder')) {
    let prm = '';

    if (params.sortOrder === 'descend') {
      prm += '-';
    }

    prm += params.sortField;

    params.sort = prm;
  }

  try {
    return await xhr().get(`/${uri}/`, {
      params: { ...params },
      cancelToken,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      throw e;
    } else {
      notification.open({
        message: 'Fout opgetreden',
        description: 'Er is een fout opgetreden bij het ophalen van de gegevens',
        icon: <ExceptionOutlined style={{ color: '#108ee9' }} />,
      });

      throw e;
    }
  }
};

export const getEntity = async (uri, id) => {
  try {
    await xhr().get(`/${uri}/${id}/`);
  } catch (e) {
    notification.open({
      message: 'Fout opgetreden',
      description: 'Er is een fout opgetreden bij het ophalen van de gegevens',
      icon: <ExceptionOutlined style={{ color: '#108ee9' }} />,
    });

    throw e;
  }
};

export const createEntity = async (uri, values) => {
  try {
    await xhr().post(`/${uri}/`, values);

    notification.open({
      message: 'Info',
      description: 'De wijzigingen zijn opgeslagen',
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  } catch (e) {
    notification.open({
      message: 'Fout opgetreden',
      description: 'Er is een fout opgetreden bij het opslaan',
      icon: <ExceptionOutlined style={{ color: '#108ee9' }} />,
    });

    throw e;
  }
};

export const updateEntity = async (uri, id, values) => {
  try {
    await xhr().patch(`/${uri}/${id}/`, values);

    notification.open({
      message: 'Info',
      description: 'De wijzigingen zijn opgeslagen',
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  } catch (e) {
    notification.open({
      message: 'Fout opgetreden',
      description: 'Er is een fout opgetreden bij het opslaan',
      icon: <ExceptionOutlined style={{ color: '#108ee9' }} />,
    });

    throw e;
  }
};

export const deleteEntity = async (uri, id) => {
  try {
    return xhr()
      .delete(`/${uri}/${id}/`)
      .then(() => {
        notification.open({
          message: 'Info',
          description: 'Het item is verwijderd',
          icon: <SmileOutlined style={{ color: '#108ee9' }} />,
        });
      });
  } catch (e) {
    notification.open({
      message: 'Fout opgetreden',
      description: 'Er is een fout opgetreden bij het verwijderen. Controleer of er gerelateerde objecten bestaan.',
      icon: <ExceptionOutlined style={{ color: '#108ee9' }} />,
    });

    throw e;
  }
};
