import {
  AuditOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  FileDoneOutlined,
  IdcardOutlined,
  ProjectOutlined,
  SafetyOutlined,
} from '@ant-design/icons';
import { Avatar, Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { PRIVATE_ROUTES, PUBLIC_ROUTES, ROUTE_KEYS } from '../../routes';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router';
import React, { useEffect, useState } from 'react';

const { Sider } = Layout;
const { SubMenu } = Menu;

const mapState = state => ({
  permissions: state.permissions,
  users: state.users,
  tasks: state.tasks,
});

const mapDispatch = dispatch => ({
  refreshPermissions: dispatch.permissions.refreshPermissions,
  refreshTasks: dispatch.tasks.refreshTasks,
  getUsers: dispatch.users.getUsers,
  getCount: dispatch.tasks.getCount,
});

const getMatchedKey = location => {
  const result = Object.values({
    ...PRIVATE_ROUTES,
    ...PUBLIC_ROUTES,
  }).find(route => matchPath(`/${location.pathname.split('/')[1]}`, route));

  if (result) {
    return result.path;
  }

  return '';
};

function Navigation(props) {
  const [collapsed, setCollapsed] = useState(false);

  const doLogout = () => {
    localStorage.clear();

    window.location.href = '/';
  };

  return (
    <Sider
      style={{
        display: props.location.pathname === '/login' ? 'none' : 'block',
      }}
      collapsible
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={(isCollapsed, type) => {
        setCollapsed(isCollapsed);
      }}
    >
      <div
        style={{
          paddingLeft: 22,
          paddingTop: 10,
          paddingBottom: 10,
          borderTop: '1px solid #59596f',
          borderBottom: '1px solid #59596f',
        }}
      >
        <div onClick={doLogout}>
          {/* <Avatar size="default" src={localStorage.getItem('claresse_user_photo') || undefined}>
            {localStorage.getItem('claresse_username') || undefined}
          </Avatar> */}
          {!collapsed ? (
            <span
              style={{
                paddingLeft: 10,
                color: '#ffffffa6',
                fontWeight: 'bold',
              }}
            >
              {localStorage.getItem('claresse_username') || undefined}
            </span>
          ) : null}
        </div>
      </div>

      <Menu theme="dark" selectedKeys={[getMatchedKey(props.location)]} mode="inline">
        <Menu.Item key={ROUTE_KEYS.EMPLOYEES}>
          <Link to={ROUTE_KEYS.EMPLOYEES}>
            <IdcardOutlined />
            <span>Voorraad</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default withRouter(connect(mapState, mapDispatch)(Navigation));
