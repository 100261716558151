import { createModel } from '@rematch/core';
import xhr from '../../helpers/xhr';

export const filters = createModel({
  state: {},
  reducers: {
    setFilters(state, payload) {
      return { state, ...payload };
    },
    setFilter(state, component, payload) {
      let currentState = state;

      console.log(payload);

      currentState[component] = payload;

      return currentState;
    },
    getFilters(state) {
      return state;
    },
  },
  effects: dispatch => ({
    async refreshUsers() {
      const { data } = await xhr().get(`/users/`, {
        params: {
          limit: 999,
        },
      });

      dispatch.users.setUsers(JSON.stringify(data.results));
    },
  }),
});
