import { Redirect, withRouter } from 'react-router';
import React, { useEffect, useState } from 'react';

function NoMatch() {
  const [loggedIn, setLoggedIn] = useState();

  useEffect(() => {
    setLoggedIn(!!localStorage.getItem('claresse_token'));
  }, []);

  return loggedIn ? <Redirect to="/taken" /> : <Redirect to="/login" />;
}

export default withRouter(NoMatch);
