import './App.css';
import { Button, Layout, Menu } from 'antd';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../routes';
import { PrivateRoute } from '../Wrapper/Wrapper';
import { Route, Switch, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Navigation from '../Navigation/Navigation';
import NoMatch from '../NoMatch';
import React, { useEffect, useState } from 'react';
import logo from '../../assets/img/logo.png';

const { Header } = Layout;

const mapState = state => ({
  users: state.users,
  permissions: state.permissions,
});

const mapDispatch = dispatch => ({
  refreshPermissions: dispatch.permissions.refreshPermissions,
  refreshUsers: dispatch.users.refreshUsers,
});

const App = props => {
  const [loggedIn, setLoggedIn] = useState();

  useEffect(() => {
    if (localStorage.getItem('claresse_token')) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  })

  const doLogout = () => {
    localStorage.clear();

    window.location.href = '/';
  };

  return (
    <div className="App">
      {loggedIn &&
      <Header style={{ padding: '0 25px' }}>
        <span style={{ color: '#fff', fontSize: '20px' }}>
            <img
              alt=""
              src={logo}
              style={{
                display: 'inline-block',
                width: '40px',
                marginRight: '20px',
                textAlign: 'center'
              }}
            />
            <div style={{display: 'inline-block'}}>Claresse Klantenportaal</div></span>

        <span onClick={doLogout} style={{ color: '#fff', float: 'right', cursor: 'pointer', textDecoration: 'underline' }}>
          Uitloggen
        </span>

        <span style={{ color: '#fff', float: 'right', paddingRight: '10px' }}>
          Ingelogd als {localStorage.getItem('claresse_username')}
        </span>
      </Header>}

      <Layout style={{ minHeight: '100vh' }}>
        {/* <Navigation /> */}

        <Switch>
          {Object.values(PRIVATE_ROUTES).map((route, index) => (
            <PrivateRoute key={index} {...route} />
          ))}

          {Object.values(PUBLIC_ROUTES).map((route, index) => (
            <Route key={index} {...route} />
          ))}

          <Route component={NoMatch} />
        </Switch>
      </Layout>
    </div>
  );
};

export default withRouter(connect(mapState, mapDispatch)(App));
