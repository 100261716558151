import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import App from '../App/App';
import React from 'react';

const mapState = state => ({
  users: state.users,
});

const mapDispatch = dispatch => ({
  refreshPermissions: dispatch.permissions.refreshPermissions,
});

function checkIfAuthenticated() {
  return !!localStorage.getItem('claresse_token');
}

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkIfAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const Wrapper = props => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default connect(mapState, mapDispatch)(Wrapper);
