import InventoryList from './components/Inventory/InventoryList';
import Login from './components/Auth/Login';
import NoMatch from './components/NoMatch';

// file with routes
export const ROUTE_KEYS = {
  ROOT: '/',
  LOGIN: '/login',
  INVENTORY: '/voorraad',
};

export const PRIVATE_ROUTES = {
  INVENTORY: {
    component: InventoryList,
    exact: true,
    key: ROUTE_KEYS.INVENTORY,
    path: ROUTE_KEYS.INVENTORY,
  }
};

export const PUBLIC_ROUTES = {
  ROOT: {
    component: Login,
    exact: true,
    key: ROUTE_KEYS.LOGIN,
    path: ROUTE_KEYS.LOGIN,
  },
  LOGIN: {
    component: Login,
    exact: true,
    key: ROUTE_KEYS.LOGIN,
    path: ROUTE_KEYS.LOGIN,
  },
};
