import { createModel } from '@rematch/core';
import xhr from '../../helpers/xhr';

export const users = createModel({
  state: '',
  reducers: {
    setUsers(state, payload) {
      return payload;
    },
    getUsers(state) {
      return state;
    },
  },
  effects: dispatch => ({
    async refreshUsers() {
      const { data } = await xhr().get(`/users/`, {
        params: {
          limit: 999,
        },
      });

      dispatch.users.setUsers(JSON.stringify(data.results));
    },
  }),
});
