import { createModel } from '@rematch/core';
import xhr from '../../helpers/xhr';

export const tasks = createModel({
  state: 0,
  reducers: {
    setCount(state, payload) {
      return payload;
    },
    getCount(state) {
      return state;
    },
  },
  effects: dispatch => ({
    async refreshTasks() {
      const { data } = await xhr().get(`/tasks/`, {
        params: {
          owner: localStorage.getItem('solisplan_user_id'),
          owned_tasks: true,
          is_active: true,
        },
      });

      if (data) {
        dispatch.tasks.setCount(data.count);
      }
    },
  }),
});
