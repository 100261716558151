import * as models from './models';
import { init } from '@rematch/core';
import selectPlugin from '@rematch/select';

export const store = init({
  models,
  plugins: [selectPlugin()],
});

export const { select } = store;
