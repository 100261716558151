import { API_TOKEN, API_URL } from '../Constants';
import axios from 'axios';

export default () => {
  return axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Token ${API_TOKEN()}`,
    },
  });
};
